<template>
  <v-container class="app">
    <v-row>
      <v-row>
        <v-col cols="12">
          <v-form ref="survey-effort">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="!notSearch"
                  v-model="record.observer_code"
                  filled
                  label="Observer 1:"
                  required="true"
                  :rules="required"
                  clearable
                  @input="submit"
                  :disabled="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-if="!notSearch"
                  v-model="record.second_searcher_code"
                  :items="refTables['ref_observer']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Observer 2:"
                  clearable
                  @input="submit"
                  :disabled="notSearch"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="notSearch"
                  v-model="record.reporter_name"
                  filled
                  label="Reported by:"
                  required="true"
                  :rules="required"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-if="notSearch"
                  v-model="record.observer_type"
                  :items="refTables['ref_observer_type']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Observer Type:"
                  clearable
                  @input="submit"
                  :visible="true"
                  :disabled="!notSearch"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-if="!notSearch"
                  v-model="record.lighting_status_code"
                  :items="refTables['ref_lighting']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Lighting conditions:"
                  clearable
                  @input="submit"
                  :disabled="notSearch"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="!notSearch"
                  v-model="record.lighting_other"
                  filled
                  label="Description of lighting anomolies:"
                  required="false"
                  clearable
                  @input="submit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="!notSearch"
                  v-model="record.description_of_omitted"
                  filled
                  label="Describe areas not surveyed:"
                  required="false"
                  clearable
                  @input="submit"
                  :disabled="notSearch"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="!notSearch"
                  v-model="record.omission_reason"
                  filled
                  label="Reason areas were omitted:"
                  required="false"
                  clearable
                  @input="submit"
                  :disabled="notSearch"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="record.comment"
                  filled
                  label="Comment:"
                  required="false"
                  clearable
                  @input="submit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ABRCheckbox
                  v-if="!notSearch & showIncidental"
                  v-model="record.incidental"
                  label="Incidental Observation:"
                  clearable
                  @input="submit"
                  :disabled="notSearch"
                ></ABRCheckbox>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

import support from "../plugins/support";
import ABRCheckbox from "@/components/ABRCheckbox";

export default {
  name: "Survey-Effort-Form",
  components: { ABRCheckbox },
  props: {
    notSearch: Boolean,
    selected_record: Object,
  },
  data() {
    return {
      handler: {
        submit: this.submit,
      },
      required: [(v) => !!v || "Required"],
      record: this.selected_record,
      refTables: this.$store.state.refTables,
    };
  },
  created() {
    this.$watch(
      "selected_record",
      () => {
        this.record = this.selected_record;
      },
      { deep: true }
    );
    this.$watch(
      "username",
      () => {
        console.log(this.username);
        if (this.record_observer_code == "guest") {
          this.record.observer_code == this.username;
        }
      },
      { deep: true }
    );
    if (!this.notSearch && this.record.observer_code == "guest") {
      this.record.observer_code == this.username;
    }
  },

  mounted() {
    this.record = this.selected_record;
    if (this.record.observer_code == "guest") {
      this.record.observer_code == this.username;
    }
  },
  methods: {
    submit() {
      if (this.record.observer_code == "guest") {
        this.record.observer_code == this.username;
      }
      let record = support.forceFieldType(
        support.SURVEYEFFORTFIELDS,
        this.record
      );
      record.insert_ts = support.getLocalTimestamp(new Date());
      support.saveRecord(support.SURVEYEFFORT, record);
    },
  },
  computed: {
    currentCoordinates() {
      return support.getLatitude() + " " + support.getLongitude();
    },
    showIncidental() {
      return !support.hasCrumbs(this.record);
    },
    username: {
      get() {
        return this.$store.getters.username;
      },
      set(newvalue) {
        this.record.observer_code = newvalue;
      },
    },
  },
};
</script>
