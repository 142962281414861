import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import qs from "qs";
import localforage from "localforage";

Vue.use(Vuex);

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;

export default new Vuex.Store({
  state: {
    dbName: null,
    version: "1.1.3",
    tabletCode: null,
    coordinates: null,
    gpsOn: false,
    status: "",
    username: "guest",
    loggedIn: false,
    observerCode: null,
    unsavedData: false,
    ref_hotel: [
      // { title: "test database", code: "avian_downed_bird_test" },
      // { title: "1 Hotel", code: "one_hotel" },
      // { title: "Pride Of America", code: "ncl_pride_of_america" },
      // { title: "Norwegian Spirit", code: "ncl_norwegian_spirit" },
      // { title: "Royal Sonesta", code: "royal_sonesta" },
    ],
    refTables: {
      ref_lighting: [],
      ref_observer: [],
      ref_fatality_injury: [],
      ref_age: [],
      ref_sex: [],
      ref_observer_type: [],
      ref_precipitation: [],
      ref_wind_direction: [],
      ref_wind_speed: [],
      ref_bird_species: [],
      ref_turtle_species: [],
      ref_predator_species: [],
      ref_photo_type: [],
      ref_breadcrumb_location: [],
      ref_ground_cover: [],
      ref_structure_type: [],
    },
    dataTables: {
      survey_effort: [],
      survey_breadcrumb: [],
      survey_downed_bird: [],
      sos_downed_bird: [],
      survey_turtle: [],
      survey_predator: [],
      survey_photo_turtle: [],
      survey_photo_downed_bird: [],
      survey_photo_predator: [],
    },
    tablePositions: {
      survey_effort: -1,
      survey_breadcrumb: -1,
      survey_downed_bird: -1,
      sos_downed_bird: -1,
      survey_turtle: -1,
      survey_predator: -1,
      survey_photo_turtle: -1,
      survey_photo_downed_bird: -1,
      survey_photo_predator: -1,
    },
    views: {
      recent_survey_effort: [],
      recent_survey_downed_bird: [],
      recent_survey_turtle: [],
      recent_survey_predator: [],
    },
  },
  getters: {
    dbName: (state) => state.dbName,
    tabletCode: (state) => state.tabletCode,
    authStatus: (state) => state.status,
    username: (state) => state.username,
    isLoggedIn: (state) => state.loggedIn,
    observerCode: (state) => state.observerCode,
    version: (state) => state.version,
    refHotel: (state) => state.ref_hotel,
    locationOn: (state) => state.coordinates != null,
  },
  mutations: {
    setUnsavedData(state, value) {
      state.unsavedData = value;
    },
    setDbName(state, dbName) {
      state.dbName = dbName;
    },
    setTabletCode(state, tabletCode) {
      state.tabletCode = tabletCode;
    },
    auth_request(state) {
      state.status = "loading";
      state.loggedIn = false;
      state.username = "guest";
    },
    auth_success(state, username) {
      state.status = "success";
      state.loggedIn = true;
      state.username = username;
    },
    auth_error(state) {
      state.status = "error";
      state.loggedIn = false;
      Vue.$cookies.remove("auth");
    },
    logout(state) {
      state.status = "";
      state.loggedIn = false;
      state.username = "guest";
      Vue.$cookies.remove("auth");
    },
    setObserverCode(state, observerCode) {
      state.observerCode = observerCode;
    },
    refreshRecords(state, payload) {
      if (payload.tableType != null) {
        state[payload.tableType][payload.tableName] = payload.records;
      } else {
        state[payload.tableName] = payload.records;
      }
    },
    addRecord(state, info) {
      state.dataTables[info.table].push(info.record);
    },
    shiftPosition(state, info) {
      state.tablePositions[info.table] = info.position;
    },
    setCoordinates(state, coords) {
      state.coordinates = coords;
    },
    setGPSState(state, gpsState) {
      state.gpsOn = gpsState;
    },
  },
  actions: {
    async setDbName({ commit }, dbName) {
      if (dbName && dbName.length > 0) {
        await localforage.setItem("dbName", dbName);
      } else {
        await localforage.getItem("dbName").then(function (value) {
          if (value) {
            dbName = value;
          }
        });
      }
      commit("setDbName", dbName);
    },
    async setTabletCode({ commit }) {
      let tabletCode = null;
      await Promise.all([
        localforage.getItem("tabletCode").then(function (value) {
          if (value) {
            tabletCode = value;
          } else {
            tabletCode = "";
            let characters = "ABCDEF0123456789";
            let charactersLength = characters.length;
            for (let i = 0; i < 6; i++) {
              tabletCode += characters.charAt(
                Math.floor(Math.random() * charactersLength)
              );
            }
            localforage.setItem("tabletCode", tabletCode);
          }
        }),
      ]);
      commit("setTabletCode", tabletCode);
    },
    async login({ commit }, user) {
      try {
        commit("auth_request");
        let response = await axios.post(
          `${user.dbName}/login/`,
          qs.stringify(user)
        );
        if (response.status === 200) {
          commit("auth_success", user.username);
        } else {
          commit("auth_error");
        }
      } catch (err) {
        console.log(`err: ${err.message}`);
        commit("auth_error");
      }
    },
    async logout({ commit }) {
      let dbName = null;
      await localforage.getItem("dbName").then((v) => {
        if (v && v.length > 0) {
          dbName = v;
        }
      });
      console.log(dbName);
      let response = await axios.post(`${dbName}/logout/`).catch((err) => {
        console.log("logout error " + err);
        commit("logout");
      });
      if (response.status == 200) {
        commit("logout");
      } else {
        console.log("logout error!");
        commit("logout");
      }
    },
    async checkAuthentication({ commit }) {
      if (Vue.$cookies.get("auth")) {
        commit("auth_success");
      }
    },
  },
  modules: {},
});
