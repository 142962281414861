<template>
  <v-app id="app">
    <v-app-bar app clipped-left dense color="primary" dark>
      <v-app-bar-title> Downed Bird v{{ version }}</v-app-bar-title
      ><v-spacer></v-spacer
      ><v-app-bar-title app>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon to="/" v-on="on" v-bind="attrs">
              <v-icon>mdi-home-circle</v-icon>
            </v-btn>
          </template>
          <span> Return home</span>
        </v-tooltip>
        <!-- <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon to="/help" v-on="on" v-bind="attrs">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span> Help</span>
        </v-tooltip> -->
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="save">
              <v-icon>mdi-content-save-all</v-icon>
            </v-btn>
          </template>
          <span> Save and submit data</span>
        </v-tooltip>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              to="/search"
              v-on="on"
              v-bind="attrs"
              @click="newSearch"
            >
              <v-icon>mdi-walk</v-icon>
            </v-btn>
          </template>
          <span> Start a new search</span>
        </v-tooltip>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              to="/summary"
              v-on="on"
              v-bind="attrs"
              :disabled="!loggedIn"
            >
              <v-icon>mdi-table</v-icon>
            </v-btn>
          </template>
          <span> View submitted data</span>
        </v-tooltip>
      </v-app-bar-title>
      <!-- <div id="nav" class="vld-parent">
        <nav v-if="loggedIn">
          <router-link to="/summary">Summary</router-link>
        </nav>
      </div> -->
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer color="primary" app fixed dark v-if="loggedIn">
      <p class="ma-0 pa-0">Logged in as {{ $store.getters.username }}</p>
      <p class="ma-0 pa-0">
        Database: {{ $store.getters.dbName }}, Tablet code:
        {{ $store.getters.tabletCode }}
      </p>
      <v-spacer />
      <v-icon v-if="gettingLocation" class="good">mdi-map-marker</v-icon>
      <v-icon v-else class="alert">mdi-map-marker-alert</v-icon>
      <v-btn color="success" @click="logout">Logout</v-btn>
    </v-footer>
    <v-footer class="ma-0 pa-0" v-else color="warning" app fixed dark>
      <p class="ma-0 pa-0">Not logged in</p>
      <v-spacer />
      <p class="ma-0 pa-0">
        Database: {{ $store.getters.dbName }}, Tablet code
        {{ $store.getters.tabletCode }}
      </p>
      <v-spacer />
      <v-icon v-if="gettingLocation" class="good">mdi-map-marker</v-icon>
      <v-icon v-else class="alert">mdi-map-marker-alert</v-icon>
      <v-btn color="secondary" @click="loginRedirect($router.currentRoute.path)"
        >Login</v-btn
      >
    </v-footer>
  </v-app>
</template>

<script>
import localforage from "localforage";
//import { extendPrototype } from "localforage-startswith";
import axios from "axios";
//const localforage = extendPrototype(localForage);

// NOTE: this is the base URL, all axios.get/post will need
// this.$store.getters.dbName prepended to route
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;
import support from "@/plugins/support";

export default {
  name: "App",
  data: () => ({}),
  created() {
    localforage.config({
      name: "downedBird",
      version: 1,
    });
    this.$watch(
      "loggedIn",
      () => {
        console.log(this.$store.getters.username);
      },
      { deep: true }
    );
  },
  computed: {
    gettingLocation() {
      return this.$store.getters.locationOn;
    },
    loggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    version() {
      return this.$store.getters.version;
    },
  },
  async mounted() {},
  methods: {
    loginRedirect: function (path) {
      this.$router.push({
        name: "login",
        query: { redirect: path },
      });
    },
    logout: function () {
      this.$store.dispatch("logout");
    },
    save() {
      support.submitData();
    },
    newSearch() {
      support.newSurveyEffort();
    },
  },
};
</script>

<style>
html {
  overflow-y: auto !important;
}
.v-application--wrap {
  min-height: 100% !important;
}
body {
  overscroll-behavior: contain;
}

#nav {
  padding: 30px;
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: #f9fafc;
}

#nav a.router-link-exact-active {
  color: #ab42b9;
}

i.v-icon.alert {
  color: red;
}
i.v-icon.good {
  color: green;
}
</style>
