<template>
  <div>
    <!-- <v-btn icon to="/photo"> -->
    <v-file-input
      type="file"
      accept="image/*"
      capture="environment"
      id="photo-input"
      ref="photo-input"
      prepend-icon="mdi-camera"
      @change="onPhotoAccepted"
      hide-input
    />
    <canvas
      id="full-size-canvas"
      :height="maxHeight"
      :width="maxWidth"
      hidden
    />
    <v-data-table
      :headers="headers"
      :items="records"
      item-key="photo_file_name"
      sort-by="ts"
      group-by="photo_type_code"
      class="elevation-1"
      hide-default-footer
    ></v-data-table>
  </div>
</template>
<script>
import support from "../plugins/support";

export default {
  name: "Photo-List",
  components: {},
  props: {
    records: Array,
  },
  data() {
    return {
      maxHeight: 2880,
      maxWidth: 3840,
      handler: {},
      headers: [
        {
          value: "photo_file_name",
          text: "Photo Name",
        },
      ],
    };
  },
  methods: {
    openCamera() {
      this.$emit("cameraClicked");
    },
    onPhotoAccepted: async function (e) {
      const fullSizeCanvas = document.getElementById("full-size-canvas");
      const fullSizeContext = fullSizeCanvas.getContext("2d");
      let img = new Image();
      img.addEventListener("load", () => {
        const widthHeightRatio = img.width / img.height;
        const fullSizeHeight = Math.min(3840 / widthHeightRatio, 2880);
        const fullSizeWidth = Math.min(2880 * widthHeightRatio, 3840);
        fullSizeContext.drawImage(img, 0, 0, fullSizeWidth, fullSizeHeight);
        let photoData = fullSizeCanvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");

        let photoFilename = this.setFileName();

        let payload = {};
        payload.data = photoData.replace("data:image/octet-stream;base64,", "");
        payload.photo_file_name = photoFilename;
        if (photoData.length > 100) {
          this.$emit("photoAccepted", payload);
          this.downloadPhoto(payload);
        }
      });
      if (e !== null) {
        img.src = URL.createObjectURL(e);
      }
    },
    setFileName() {
      const d = new Date();
      return `${this.$store.getters.dbName}_${
        this.$store.getters.tabletCode
      }_${support
        .getLocalTimestamp(d)
        .replace(/ /g, "T")
        .replace(/:/g, "")}.jpeg`;
    },
    downloadPhoto(obj) {
      const filename = obj.photo_file_name;
      // old skool DOM manipulation, not great...
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = obj.photoData;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.parentNode.removeChild(link);
      }, 0);
    },
  },
};
</script>
