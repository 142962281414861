<template>
  <v-container>
    <SurveyEffort
      :selected_record="survey_effort_record"
      :notSearch="notSearch"
      v-on="handler"
    />
    <BreadCrumb
      v-if="!notSearch & !asBoolean(survey_effort_record.incidental)"
      :selected_record="survey_effort_record"
      v-on="handler"
    />
    <v-tabs v-model="tab">
      <v-tabs-slider color="blue"></v-tabs-slider>
      <v-tab v-for="page in pages" :key="page"> {{ page }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="page in pages" :key="page">
        <ObservationForm
          v-if="survey_effort_record.insert_ts != ''"
          :surveyTs="survey_effort_record.survey_ts"
          :tabType="page"
          v-on="handler"
          :notSearch="notSearch"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import support from "../plugins/support";
import SurveyEffort from "@/components/SurveyEffort";
import BreadCrumb from "@/components/BreadCrumb";
import ObservationForm from "@/components/Observation";

export default {
  name: "Search-Form",

  components: {
    SurveyEffort,
    BreadCrumb,
    ObservationForm,
  },
  props: {
    datatype: String,
  },
  created() {},
  data() {
    return {
      handler: {
        dropCrumb: this.dropCrumb,
      },
      tab: null,
      required: [(v) => !!v || "Required"],
      pages: ["Downed Bird", "Predator", "Turtle"],
    };
  },
  computed: {
    hasSurvey() {
      if (
        this.notSearch &
        (this.survey_effort_record.reporter_name != "" &&
          this.survey_effort_record.reporter_name != "guest")
      ) {
        return true;
      } else if (!this.notSearch & (this.insert_ts != "")) {
        return true;
      } else {
        return false;
      }
    },
    survey_effort_record() {
      return support.provideRecord(support.SURVEYEFFORT);
    },
    notSearch() {
      return !this.$store.getters.isLoggedIn;
    },
    dbName() {
      return this.$store.getters.dbName;
    },
    databaseSet() {
      return !(
        this.dbName == undefined ||
        this.dbName == null ||
        this.dbName == ""
      );
    },
  },
  async mounted() {
    support.startWatch();
    this.$watch(
      "dbName",
      () => {
        this.kickback();
      },
      { deep: true }
    );
    this.$watch(
      "survey_effort_record",
      () => {
        console.log("record changed" + this.survey_effort_record.insert_ts);
      },
      { deep: true }
    );
    if (!this.notSearch && this.survey_effort_record.observer_code == "guest") {
      console.log(this.$store.getters.username);
      console.log(this.survey_effort_record.observer_code);
      this.survey_effort_record.observer_code == this.$store.getters.username;
    }
  },
  methods: {
    dropCrumb(crumbInfo) {
      support.saveRecord(support.SURVEYEFFORT, this.survey_effort_record);
      let record = support.newBreadCrumb();
      record.breadcrumb_location_code = crumbInfo.code;
      record.ts = crumbInfo.ts;
      support.saveRecord(support.SURVEYBREADCRUMB, record);
      support.submitData();
    },
    asBoolean(b) {
      return support.asBoolean(b);
    },
    kickback() {
      if (this.needsDb) {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>
