<template>
  <div>
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="records"
      @click:row="handleRowClick"
    ></v-data-table>
  </div>
</template>
<script>
export default {
  name: "Record-List",
  components: {},
  props: {
    records: Array,
    fields: Array,
  },
  data() {
    return {};
  },
  methods: {
    handleRowClick(record, index) {
      console.log(index);
      this.$emit("record_selected", index);
    },
  },
  computed: {
    headers() {
      return this.fields
        .filter(function (el) {
          return el["label"] != null;
        })
        .map((item) => ({ value: item.key, text: item.label }));
    },
  },
};
</script>
