<template>
  <v-container>
    <v-snackbar> </v-snackbar>
    <v-card justify="center">
      <!-- <v-card-title>Take a Photo</v-card-title> -->
      <v-card-text>
        <v-row>
          <v-btn @click="toggleCamera">Close Camera</v-btn>
        </v-row>
        <v-row v-if="isCameraOpen">
          <v-container v-if="isCameraOpen" class="camera-box">
            <video
              v-show="!isPhotoTaken"
              ref="camera"
              :width="previewWidth"
              :height="previewHeight"
              autoplay
            ></video>
            <canvas
              v-show="isPhotoTaken"
              ref="previewCanvas"
              :width="previewWidth"
              :height="previewHeight"
            ></canvas>
            <canvas
              ref="fullSizeCanvas"
              :width="fullSizeWidth"
              :height="fullSizeHeight"
              hidden
            ></canvas>
          </v-container>
        </v-row>
        <v-row v-if="isCameraOpen">
          <div v-if="!isPhotoTaken">
            <v-btn @click="takePhoto">Take Photo</v-btn>
          </div>
          <div v-else>
            <v-btn @click="takePhoto">Discard Photo</v-btn>
          </div>
          <div v-if="isPhotoTaken">
            <v-btn @click="acceptPhoto">Accept Photo</v-btn>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import support from "../plugins/support";

export default {
  name: "TakePhoto",
  components: {},
  data: () => ({
    // isCameraOpen: false,
    isPhotoTaken: false,
    isShotPhoto: false,
    isLoading: false,
    photoData: null,
    isAccepted: false,
  }),
  props: {
    autoDownload: Boolean,
    previewHeight: Number,
    previewWidth: Number,
    fullSizeHeight: Number,
    fullSizeWidth: Number,
    isCameraOpen: Boolean,
  },
  created() {
    this.$watch(
      "isCameraOpen",
      () => {
        this.toggleCamera();
      },
      { deep: true }
    );
  },
  async mounted() {
    //support.checkTabletDbLoginStatus();
    this.createCameraElement();
  },
  computed: {},
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        //this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        try {
          this.stopCameraStream();
        } catch (err) {
          console.log("camera error");
        }
        this.$emit("cameraClose");
      } else {
        //this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: { facingMode: "environment" },
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          alert(error);
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    acceptPhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
        this.setFileName();
      }
      this.photoData = this.$refs.fullSizeCanvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      this.$emit("photoAccepted", {
        photo_file_name: this.photoFilename,
        data: this.photoData.replace("data:image/octet-stream;base64,", ""),
      });
      if (this.autoDownload) {
        this.downloadPhoto();
      }

      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.toggleCamera();
      //this.$emit("cameraClose");
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
        this.setFileName();
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      if (this.isPhotoTaken) {
        const previewContext = this.$refs.previewCanvas.getContext("2d");
        previewContext.drawImage(
          this.$refs.camera,
          0,
          0,
          this.previewWidth,
          this.previewHeight
        );

        const widthHeightRatio =
          this.$refs.camera.width / this.$refs.camera.height;

        const fullSizeHeight = Math.min(
          this.fullSizeWidth / widthHeightRatio,
          this.fullSizeHeight
        );
        const fullSizeWidth = Math.min(
          this.fullSizeHeight * widthHeightRatio,
          this.fullSizeWidth
        );
        const fullSizeContext = this.$refs.fullSizeCanvas.getContext("2d");
        fullSizeContext.drawImage(
          this.$refs.camera,
          0,
          0,
          fullSizeWidth,
          fullSizeHeight
        );
        this.photoData = this.$refs.fullSizeCanvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");

        // if (this.autoDownload) {
        //   this.downloadPhoto();
        // }
      } else {
        this.photoData = null;
      }
      this.$emit("photoTaken", {
        photo_file_name: this.photoFilename,
        data: this.photoData,
      });
    },
    setFileName() {
      const d = new Date();
      this.photoFilename = `${this.$store.getters.dbName}_${
        this.$store.getters.tabletCode
      }_${support
        .getLocalTimestamp(d)
        .replace(/ /g, "T")
        .replace(/:/g, "")}.jpeg`;
    },
    downloadPhoto() {
      //const d = new Date();
      const filename = this.photoFilename;
      // const filename = `${this.$store.getters.dbName}_${
      //   this.$store.getters.tabletCode
      // }_${support
      //   .getLocalTimestamp(d)
      //   .replace(/ /g, "T")
      //   .replace(/:/g, "")}.jpeg`;
      // old skool DOM manipulation, not great...
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = this.photoData;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.parentNode.removeChild(link);
      }, 0);
    },
  },
};
</script>

<style></style>
