<template>
  <v-container class="app">
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12">
          <v-form ref="downed_bird">
            Downed Bird Information. Don't forget to take photos!.
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.field_species_code"
                  :items="refTables['ref_bird_species']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Species:"
                  clearable
                  @input="submit"
                  :disabled="record.observation_ts === ''"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.observation_ts"
                  filled
                  label="Observation Time:"
                  required="true"
                  :rules="required"
                  clearable
                  @input="submit"
                  :visible="true"
                  :disabled="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.age_code"
                  :items="refTables['ref_age']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Age:"
                  clearable
                  @input="submit"
                  :disabled="record.observation_ts === ''"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.sex_code"
                  :items="refTables['ref_sex']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Sex:"
                  clearable
                  @input="submit"
                  :disabled="record.observation_ts === ''"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="record.incident_reporter"
                      filled
                      label="Incident Reporter:"
                      clearable
                      @input="submit"
                      :visible="true"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span> Who originally observed the down bird?</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <ABRDatetime
                        v-model="record.incident_ts"
                        :ts="record.incident_ts"
                        label="Incident Time"
                        v-on:datetimeset="onDateTimeSet($event, 'incident_ts')"
                      >
                      </ABRDatetime>
                    </span>
                  </template>
                  When was the bird first observed?
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-select
                      v-model="record.fatality_injured_code"
                      :items="refTables['ref_fatality_injury']"
                      item-text="title"
                      item-value="code"
                      filled
                      label="Fatality or Injured:"
                      clearable
                      @input="submit"
                      :disabled="record.observation_ts === ''"
                      v-on="on"
                    >
                    </v-select>
                  </template>
                  <span> Is the bird injured or is this a fatality.</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.location_description"
                  filled
                  label="Location Description:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.nearest_structure_type"
                  :items="refTables['ref_structure_type']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Nearest Structure Type:"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.nearest_structure_desc"
                  filled
                  label="Structure Description:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.ground_cover_type_code"
                  :items="refTables['ref_ground_cover']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Ground Cover:"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.ground_cover_height"
                  filled
                  type="number"
                  label="Ground Cover Height (cm):"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.current_precipitation_code"
                  :items="refTables['ref_precipitation']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Current Precipitation:"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.previous_precipitation_code"
                  :items="refTables['ref_precipitation']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Precipitation last 24 hours:"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.current_wind_speed_code"
                  :items="refTables['ref_wind_speed']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Current Wind Speed:"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.previous_wind_speed_code"
                  :items="refTables['ref_wind_speed']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Wind speed last 24 hours:"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.current_wind_direction_code"
                  :items="refTables['ref_wind_direction']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Current Wind Direction:"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.previous_wind_direction_code"
                  :items="refTables['ref_wind_direction']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Wind direction last 24 hours:"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.action_taken"
                  filled
                  label="Action Taken:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.specimen_condition"
                  filled
                  label="Specimen Condition:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <ABRDatetime
                  v-model="record.drop_ts"
                  :ts="record.drop_ts"
                  label="Drop Off Time"
                  v-on:datetimeset="onDateTimeSet($event, 'drop_ts')"
                ></ABRDatetime>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.drop_location"
                  filled
                  label="Drop Off Location:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="record.pickup_person"
                      filled
                      label="Pickup Person:"
                      clearable
                      @input="submit"
                      :visible="true"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span> Who picked up the injured or deceased bird.</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.comment"
                  filled
                  label="Comment:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
// @ is an alias to /src
import ABRDatetime from "@/components/ABRDatetime";
import support from "@/plugins/support";
export default {
  name: "Downed-Bird-Form",
  components: { ABRDatetime },
  props: {
    photo_records: Array,
    selected_record: Object,
    notSearch: Boolean,
  },
  data() {
    return {
      handler: {
        submit: this.submit,
      },
      required: [(v) => !!v || "Required"],
      refTables: this.$store.state.refTables,
      tableName: "survey_downed_bird",
      record: this.selected_record,
    };
  },
  mounted() {
    this.$watch(
      "selected_record",
      () => {
        this.record = this.selected_record;
      },
      { deep: true }
    );
  },
  methods: {
    submit() {
      let record = support.forceFieldTypes(
        support.SURVEYDOWNEDBIRD,
        this.record
      );
      support.saveRecord(support.SURVEYDOWNEDBIRD, record);
    },
    onDateTimeSet(value, parameter) {
      this.record[parameter] = support.getLocalTimestamp(new Date(value));
      this.submit();
    },
  },

  computed: {},
};
</script>
